import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Session = {
  companyId: number;
  companyBaseId: number;
  companyBaseCode: string;
  companyPaymentType: CompanyPaymentConfig;
  approveTosAgreementRequired: boolean;
  registerUserInformationRequired: boolean;
  serviceType: ServiceType;
};

type SessionState = {
  loading: boolean;
  loaded: boolean; // 初回のデータ取得が完了したかどうか
  errorMessages: string[];
  session?: Session;
};

const initialState: SessionState = {
  loading: true,
  loaded: false,
  errorMessages: [],
};

type SessionLoadSuccessAction = PayloadAction<{
  session?: Session;
}>;

type SessionLoadFailedAction = PayloadAction<{
  messages: string[];
}>;

type SessionUpdateSuccessAction = PayloadAction<{
  session?: Session;
}>;

type SessionUpdateFailedAction = PayloadAction<{
  messages: string[];
}>;

type LoginSuccessAction = PayloadAction<{
  session?: Session;
}>;

export const session = createSlice({
  name: 'session',
  initialState,
  reducers: {
    load: (state) => ({
      ...state,
      loading: true,
      errorMessages: [],
    }),
    loadSuccess: (state, action: SessionLoadSuccessAction) => ({
      ...state,
      loading: false,
      loaded: true,
      errorMessages: [],
      session: action.payload.session,
    }),
    loadFailed: (state, action: SessionLoadFailedAction) => ({
      ...state,
      loading: false,
      loaded: true,
      errorMessages: action.payload.messages,
    }),
    update: (state) => state,
    updateSuccess: (state, action: SessionUpdateSuccessAction) => ({
      ...state,
      session: action.payload.session,
    }),
    updateFailed: (state, action: SessionUpdateFailedAction) => ({
      ...state,
      errorMessages: action.payload.messages,
    }),
    loginSuccess: (state, action: LoginSuccessAction) => ({
      ...state,
      loading: false,
      loaded: true,
      session: action.payload.session,
    }),
  },
});
