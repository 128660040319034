import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';
import { routes } from '../../config/routes';

interface VerifyCompanyBase {
  isLoading: boolean;
  errorMessages: string[];
  companyBaseCode: string;
  verificationCode: string;
  companyBase?: CompanyBaseInfo;
}

const initialState: VerifyCompanyBase = {
  isLoading: false,
  errorMessages: [],
  companyBaseCode: '',
  verificationCode: '',
};

type VerifyCompanyBaseSuccessAction = PayloadAction<{
  companyBaseCode: string;
  verificationCode: string;
  companyId: number;
  companyName: string | undefined;
  companyBaseId: number;
  companyBaseName: string;
  companyBases: CompanyBasesInfo[] | undefined;
}>;

type VerifyCompanyBaseFailedAction = PayloadAction<{
  messages: string[];
}>;

export const verifyCompanyBase = createSlice({
  name: 'verifyCompanyBase',
  initialState,
  reducers: {
    verifyCompanyBase: (state: VerifyCompanyBase) => ({
      ...state,
      isLoading: true,
      errorMessages: [],
    }),
    verifyCompanyBaseSuccess: (
      _: VerifyCompanyBase,
      action: VerifyCompanyBaseSuccessAction,
    ) => ({
      companyBase: {
        companyId: action.payload.companyId,
        companyName: action.payload.companyName,
        companyBaseId: action.payload.companyBaseId,
        companyBaseName: action.payload.companyBaseName,
        companyBases:
          action.payload.companyBases !== undefined
            ? action.payload.companyBases
            : [],
      },
      companyBaseCode: action.payload.companyBaseCode,
      verificationCode: action.payload.verificationCode,
      isLoading: false,
      errorMessages: [],
    }),
    verifyCompanyBaseFailed: (
      state: VerifyCompanyBase,
      action: VerifyCompanyBaseFailedAction,
    ) => ({
      ...state,
      isLoading: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, (state, action) => {
      // 新規登録時の場合stateを保持する
      if (action.payload.oldUrl === routes.registerCompanyBase) {
        return state;
      }
      return initialState;
    });
  },
});
