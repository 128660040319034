import React from 'react';

import { Head } from '../components/common/Head/Head';
import { NonSignedInLayout } from '../components/layouts/NonSignedInLayout';
import { LoginPanelContainer } from '../containers/LoginPanelContainer/LoginPanelContainer';
import { PageBaseContainer } from '../containers/PageBaseContainer';

const Login = () => (
  <div>
    <Head title="ログイン" noindex />

    <PageBaseContainer authRequired={false} authShouldBeRedirected={true}>
      <NonSignedInLayout>
        <LoginPanelContainer />
      </NonSignedInLayout>
    </PageBaseContainer>
  </div>
);

export default Login;
