import { configureStore, DeepPartial } from '@reduxjs/toolkit';

import { middleware } from './middlewares';
import { reducer } from './reducer';
import { Action, State } from './types';

export const getStore = (preloadedState?: DeepPartial<State>) =>
  configureStore<State, Action, typeof middleware>({
    reducer,
    middleware,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    preloadedState,
  });
