// Theme for styled-components
import { DefaultTheme } from 'styled-components';

import { large, middle, small } from './breakpoints';

export const theme: DefaultTheme = {
  palette: {
    primary: {
      main: '#FBC600',
    },
    secondary: {
      main: '#4A90E2',
    },
    error: {
      main: '#E7465A',
    },
  },
  spacing: (space: number) => 4 * space, // marginの基本単位
  variables: {
    headerHeight: '56px',
    footerHeightPc: '32px',
  },
  font: {
    fontFamily:
      "'Hiragino Kaku Gothic ProN', -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', YuGothic, 'Yu Gothic Medium', 'HirIagino Sans', Meiryo, sans-serif",
    fontSize: {
      h1: '24px',
      h2: '18px',
      h3: '14px',
      subtitle: '14px',
      body: '12px',
      body2: '10px',
      price: '22px',
    },
  },
  base: {
    backgroundColor: '#F6F6F6',
    backgroundColor3: '#FFFBE7', // 通知に利用する背景色
  },
  colors: {
    red: '#E7465A',
    blue: '#4A90E2',
    aqua: '#5ABAFF',
    yellow: '#FBC600',
    white: '#FFFFFF',
    gray: '#999999',
    whiteGray: '#C4C4C4',
    black: '#111111',
    black2: '#333333', // 文章だが、注意書き等に利用するもの
  },
  border: {
    normal: '#C4C4C4',
    error: '#E7465A',
  },
  breakPoints: {
    small,
    middle,
    large,
  },
};
