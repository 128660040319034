import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface CancelOrderState {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
  payload: {
    order?: CancelOrder;
    orderableTime?: string;
  };
}

const initialState: CancelOrderState = {
  loading: false,
  isError: false,
  errorMessages: [],
  payload: {
    order: undefined,
    orderableTime: undefined,
  },
};

type ConfirmCancelOrderSuccessAction = PayloadAction<{
  order: CancelOrder;
}>;

type ConfirmCancelOrderFailedAction = PayloadAction<{
  messages: string[];
}>;

type CancelOrderSuccessAction = PayloadAction<{
  orderableTime: string;
}>;

type CancelOrderFailedAction = PayloadAction<{
  messages: string[];
}>;

export const cancelOrder = createSlice({
  name: 'cancelOrder',
  initialState,
  reducers: {
    cancelOrder: () => ({
      loading: true,
      isError: false,
      errorMessages: [],
      payload: {
        order: undefined,
      },
    }),
    confirmCancelOrderSucceed: (
      _,
      action: ConfirmCancelOrderSuccessAction,
    ) => ({
      loading: false,
      isError: false,
      errorMessages: [],
      payload: {
        order: action.payload.order,
      },
    }),
    confirmCancelOrderFailed: (
      state,
      action: ConfirmCancelOrderFailedAction,
    ) => ({
      ...state,
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
    cancelOrderSucceed: (state, action: CancelOrderSuccessAction) => ({
      loading: false,
      isError: false,
      errorMessages: [],
      payload: {
        order: state.payload.order,
        orderableTime: action.payload.orderableTime,
      },
    }),
    cancelOrderFailed: (state, action: CancelOrderFailedAction) => ({
      ...state,
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
