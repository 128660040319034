import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type ConnectWithSlackState = {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
};

const initialState: ConnectWithSlackState = {
  loading: false,
  isError: false,
  errorMessages: [],
};

type ConnectFailedAction = PayloadAction<{
  messages: string[];
}>;

export const connectWithSlack = createSlice({
  name: 'connectWithSlack',
  initialState,
  reducers: {
    connectStart: (state) => ({
      ...state,
      loading: true,
    }),
    connectSuccess: (state) => ({
      ...state,
      loading: false,
      isError: false,
      errorMessages: [],
    }),
    connectFailed: (state, action: ConnectFailedAction) => ({
      ...state,
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, (state, action) => {
      // Slack認証callbackからの戻りの場合stateを保持する see also
      // https://github.com/stafes/gochikuru-now-front/pull/229
      if (action.payload.oldUrl === '/slack_oauth_callback') {
        return state;
      }
      return initialState;
    });
  },
});
