import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface LunchByDate {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
  payload: {
    orderStart: string | null;
    orderEnd: string | null;
    lunchByDate: {
      arriveDate: string;
      lunch: Lunch[];
    }[];
  };
}

const initialState: LunchByDate = {
  loading: true,
  isError: false,
  errorMessages: [],
  payload: {
    orderStart: null,
    orderEnd: null,
    lunchByDate: [],
  },
};

type LunchGetSuccessAction = PayloadAction<{
  lunchByDate: {
    arriveDate: string;
    lunch: Lunch[];
  }[];
  orderStart: string | null;
  orderEnd: string | null;
}>;

type LunchFailedAction = PayloadAction<{
  messages: string[];
}>;

export const lunch = createSlice({
  name: 'lunch',
  initialState,
  reducers: {
    get: () => ({
      loading: true,
      isError: false,
      errorMessages: [],
      payload: {
        orderStart: null,
        orderEnd: null,
        lunchByDate: [],
      },
    }),
    getSuccess: (_, action: LunchGetSuccessAction) => ({
      loading: false,
      isError: false,
      errorMessages: [],
      payload: {
        orderStart: action.payload.orderStart,
        orderEnd: action.payload.orderEnd,
        lunchByDate: action.payload.lunchByDate,
      },
    }),
    getFailed: (state, action: LunchFailedAction) => ({
      ...state,
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
