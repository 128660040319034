import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';

import React from 'react';

import NextApp, { AppProps } from 'next/app';
import Router from 'next/router';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/node';

import { gtmConfig } from '../config/tracking';
import { withReduxStore } from '../hoc/withReduxStore';
import { router } from '../store/modules/router';
import { Store } from '../store/types';
import { AppStylesProvider } from '../styles/AppStylesProvider';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
globalThis._sentryEsmLoaderHookRegistered = true;
Sentry.init({
  enabled: process.env.SENTRY_ENABLED === 'true',
  dsn: process.env.SENTRY_DSN,
  release: process.env.SENTRY_RELEASE,
});

interface Props extends AppProps {
  store: Store;
}

class App extends NextApp<Props> {
  constructor(props: Props) {
    super(props);

    const route = props.router.route; // ServerRouter
    const { store } = props;
    const { dispatch } = store;

    // 初回のレンダリング時はrouteChangeCompleteが発火しないため、ここで発火させておく
    dispatch(router.actions.update({ url: route }));

    Router.events.on('routeChangeComplete', (url: string) => {
      dispatch(router.actions.update({ url }));
    });
  }

  public componentDidMount() {
    const tagManagerArgs = {
      ...gtmConfig,
      dataLayerName: 'UserDataLayer',
    };

    if (!tagManagerArgs.gtmId) {
      return;
    }

    TagManager.initialize(tagManagerArgs);
  }

  public render() {
    const { Component, pageProps, store } = this.props;

    // FIXME: Workaround for https://github.com/zeit/next.js/issues/8592
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { err } = this.props;
    return (
      <Provider store={store}>
        <AppStylesProvider>
          <Component {...pageProps} err={err} />
        </AppStylesProvider>
      </Provider>
    );
  }
}

export default withReduxStore<Props>(App);
