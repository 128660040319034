import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';
import { routes } from '../../config/routes';

interface RegisterNewMember {
  isLoading: boolean;
  errorMessages: string[];
  email: string;
}

const initialState: RegisterNewMember = {
  isLoading: false,
  errorMessages: [],
  email: '',
};

type RegisterNewMemberSuccessAction = PayloadAction<{
  email: string;
}>;

type RegisterNewMemberFailedAction = PayloadAction<{
  messages: string[];
}>;

export const registerNewMember = createSlice({
  name: 'registerNewMember',
  initialState,
  reducers: {
    registerNewMember: (state: RegisterNewMember) => ({
      ...state,
      isLoading: true,
      errorMessages: [],
    }),
    registerNewMemberSuccess: (
      _: RegisterNewMember,
      action: RegisterNewMemberSuccessAction,
    ) => ({
      isLoading: false,
      errorMessages: [],
      email: action.payload.email,
    }),
    registerNewMemberFailed: (
      state: RegisterNewMember,
      action: RegisterNewMemberFailedAction,
    ) => ({
      ...state,
      isLoading: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, (state, action) => {
      // 新規登録時の場合stateを保持する
      if (action.payload.oldUrl === routes.registerMember) {
        return state;
      }
      return initialState;
    });
  },
});
