import { Middleware, Action } from '../types';

const isFailedAction = (action: Action) => action.type.endsWith('Failed');

const requestFailed: Middleware = () => (next) => (action) => {
  if (!isFailedAction(action)) {
    return next(action);
  }

  // エラーが発生した際は通知を見れるようにページトップにスクロールする
  window.scroll({
    top: 0,
    behavior: 'smooth',
  });
  return next(action);
};

export default requestFailed;
