import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RouterState = {
  url: string;
  history: string[];
};

const initialState: RouterState = {
  url: '',
  history: [],
};

// Next RouterでRouterの更新があったことを知らせるAction
export type RouterUpdateAction = PayloadAction<{
  url: string;
}>;

// Next RouterでRouterの更新があり、かつページが変更されたことを知らせるAction
export type RouterChangeAction = PayloadAction<{
  url: string;
  oldUrl: string;
}>;

export const router = createSlice({
  name: 'router',
  initialState,
  reducers: {
    update: (state, action: RouterUpdateAction) => {
      const newHistory =
        state.history.slice(-1)[0] === action.payload.url
          ? state.history
          : [...state.history, action.payload.url];

      return {
        url: action.payload.url,
        history: newHistory,
      };
    },
    pageChange: (state, action: RouterChangeAction) => {
      const newHistory =
        state.history.slice(-1)[0] === action.payload.url
          ? state.history
          : [...state.history, action.payload.url];

      return {
        url: action.payload.url,
        history: newHistory,
      };
    },
  },
});
