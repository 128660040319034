import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface ResendRegisterMail {
  isLoading: boolean;
  errorMessages: string[];
  isSucceeded: boolean;
}

const initialState: ResendRegisterMail = {
  isLoading: false,
  errorMessages: [],
  isSucceeded: false,
};

type ResendRegisterMailFailedAction = PayloadAction<{
  messages: string[];
}>;

export const resendRegisterMail = createSlice({
  name: 'resendRegisterMail',
  initialState,
  reducers: {
    resendRegisterMail: () => ({
      isLoading: true,
      isSucceeded: false,
      errorMessages: [],
    }),
    resendRegisterMailSuccess: () => ({
      isLoading: false,
      isSucceeded: true,
      errorMessages: [],
    }),
    resendRegisterMailFailed: (_, action: ResendRegisterMailFailedAction) => ({
      isLoading: false,
      isSucceeded: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
