import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface ModifyEmailRequest {
  isLoading: boolean;
  errorMessages: string[];
}

const initialState: ModifyEmailRequest = {
  isLoading: false,
  errorMessages: [],
};

type ModifyEmailRequestFailedAction = PayloadAction<{
  messages: string[];
}>;

export const modifyEmailRequest = createSlice({
  name: 'modifyEmailRequest',
  initialState,
  reducers: {
    modifyEmailRequestStart: () => ({
      isLoading: true,
      errorMessages: [],
    }),
    modifyEmailRequestSuccess: () => ({
      isLoading: false,
      errorMessages: [],
    }),
    modifyEmailRequestFailed: (_, action: ModifyEmailRequestFailedAction) => ({
      isLoading: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
