import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type PaymentMethod = {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
  paymentMethodCard: PaymentMethodCard | undefined;
};

const initialState: PaymentMethod = {
  loading: true,
  isError: false,
  errorMessages: [],
  paymentMethodCard: undefined,
};

type PaymentMethodsGetSuccessAction = PayloadAction<{
  paymentMethodCard?: PaymentMethodCard;
}>;

type PaymentMethodsGetFailedAction = PayloadAction<{
  messages: string[];
}>;

export const paymentMethods = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    get: () => ({
      loading: true,
      isError: false,
      errorMessages: [],
      paymentMethodCard: undefined,
    }),
    getSuccess: (_, action: PaymentMethodsGetSuccessAction) => ({
      loading: false,
      isError: false,
      errorMessages: [],
      paymentMethodCard: action.payload.paymentMethodCard,
    }),
    getFailed: (_, action: PaymentMethodsGetFailedAction) => ({
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
      paymentMethodCard: undefined,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, (state, action) => {
      // 決済削除フォームへ遷移する場合はデータをリセットせず、引き継ぐ
      if (action.payload.url === '/delete_payment_method') {
        return state;
      }

      return initialState;
    });
  },
});
