import { connect } from 'react-redux';
import { State, Dispatch } from '../../store/types';
import { LoginPanel } from '../../components/features/LoginPanel';

const mapStateToProps = (state: State) => ({
  errorMessages: state.login.errorMessages,
});
const mapDispatchToProps = (_: Dispatch) => ({});

export const LoginPanelContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginPanel);
