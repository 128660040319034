import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type TosAgreementState = {
  approve: {
    approving: boolean;
    isError: boolean;
    errorMessages: string[];
  };
};

const initialState: TosAgreementState = {
  approve: {
    approving: false,
    isError: false,
    errorMessages: [],
  },
};

type TosAgreementApproveFailedAction = PayloadAction<{
  messages: string[];
}>;

export const tosAgreement = createSlice({
  name: 'tosAgreement',
  initialState,
  reducers: {
    approve: (state) => {
      state.approve = {
        approving: true,
        isError: false,
        errorMessages: [],
      };
    },
    approveSuccess: (state) => {
      state.approve = {
        approving: false,
        isError: false,
        errorMessages: [],
      };
    },
    approveFailed: (state, action: TosAgreementApproveFailedAction) => {
      state.approve = {
        approving: false,
        isError: true,
        errorMessages: action.payload.messages,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
