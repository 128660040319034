import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface CouponState {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
  coupons: Coupon[];
}

const initialState: CouponState = {
  loading: false,
  isError: false,
  errorMessages: [],
  coupons: [],
};

type CouponGetSuccessAction = PayloadAction<{
  coupons: Coupon[];
}>;

type CouponGetFailedAction = PayloadAction<{
  messages: string[];
}>;

export const coupon = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    get: () => ({
      loading: true,
      isError: false,
      errorMessages: [],
      coupons: [],
    }),
    getSuccess: (_, action: CouponGetSuccessAction) => ({
      loading: false,
      isError: false,
      errorMessages: [],
      coupons: action.payload.coupons,
    }),
    getFailed: (_, action: CouponGetFailedAction) => ({
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
      coupons: [],
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
