import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface UpdatePassword {
  isLoading: boolean;
  isError: boolean;
  errorMessages: string[];
  isSucceeded: boolean;
}

const initialState: UpdatePassword = {
  isLoading: false,
  isError: false,
  errorMessages: [],
  isSucceeded: false,
};

type UpdatePasswordFailedAction = PayloadAction<{
  messages: string[];
}>;

export const updatePassword = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {
    updatePassword: () => ({
      isLoading: true,
      isError: false,
      errorMessages: [],
      isSucceeded: false,
    }),
    updatePasswordSuccess: () => ({
      isLoading: false,
      isError: false,
      errorMessages: [],
      isSucceeded: true,
    }),
    updatePasswordFailed: (_, action: UpdatePasswordFailedAction) => ({
      isLoading: false,
      isError: true,
      errorMessages: action.payload.messages,
      isSucceeded: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
