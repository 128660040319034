import * as React from 'react';

const redirectURL =
  process.env.SLACK_OAUTH_REDIRECT_URL || 'Slack OAuth redirect URL not found';
const slackClientId =
  process.env.SLACK_OAUTH_CLIENT_ID || 'Slack OAuth Client ID not found';
const scopes = 'identity.basic';

interface Props {
  state?: string;
}

// @see https://api.slack.com/docs/sign-in-with-slack
const SignInWithSlackButton: React.FC<Props> = ({ state }) => {
  const baseUrl = `https://slack.com/oauth/v2/authorize?user_scope=${scopes}&client_id=${slackClientId}&redirect_uri=${redirectURL}`;
  const url = state ? `${baseUrl}&state=${state}` : baseUrl;

  return (
    <a href={url}>
      <img
        alt={'Sign in with Slack'}
        height="40"
        width="172"
        src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
        srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
      />
    </a>
  );
};

export { SignInWithSlackButton };
