import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${(props) => props.theme.base.backgroundColor};
    font-family: ${(props) => props.theme.font.fontFamily};
  }
`;

export { GlobalStyle };
