import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface News {
  loading: boolean;
  errorMessages: string[];
  payload: {
    newsItems: NewsItem[];
  };
}

const initialState: News = {
  loading: false,
  errorMessages: [],
  payload: {
    newsItems: [],
  },
};

type FetchNewsSuccessAction = PayloadAction<{
  newsItems: NewsItem[];
}>;

type FetchNewsFailedAction = PayloadAction<{
  messages: string[];
}>;

export const news = createSlice({
  name: 'news',
  initialState,
  reducers: {
    fetchStart: (state) => ({
      ...state,
      loading: true,
    }),
    fetchSuccess: (_, action: FetchNewsSuccessAction) => ({
      loading: false,
      errorMessages: [],
      payload: {
        newsItems: action.payload.newsItems,
      },
    }),
    fetchFailed: (state, action: FetchNewsFailedAction) => ({
      ...state,
      loading: false,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
