import _NextApp, { AppContext, AppProps } from 'next/app';
import React from 'react';
import { PreloadedState } from '@reduxjs/toolkit';
import { getStore } from '../store/configureStore';
import { Store, State } from '../store/types';

const isServer = typeof window === 'undefined';
export const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

function getOrCreateStore(initialState?: PreloadedState<State>): Store {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return getStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!Object.prototype.hasOwnProperty.call(window, __NEXT_REDUX_STORE__)) {
    window[__NEXT_REDUX_STORE__] = getStore(initialState);
  }
  return window[__NEXT_REDUX_STORE__];
}

export function withReduxStore<NextAppProps>(App: typeof _NextApp) {
  type Props = NextAppProps & AppProps & { initialReduxState: State };
  return class AppWithRedux extends React.Component<Props> {
    public static async getInitialProps(appContext: AppContext) {
      const store = getOrCreateStore();

      appContext.ctx.store = store;

      let appProps = {};
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext);
      }

      return {
        ...appProps,
        initialReduxState: store.getState(),
      };
    }
    protected store: Store;

    constructor(props: Props) {
      super(props);
      this.store = getOrCreateStore(props.initialReduxState);
    }

    public render() {
      return <App {...this.props} store={this.store} />;
    }
  };
}
