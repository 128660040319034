import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type LogoutState = {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
};

const initialState: LogoutState = {
  loading: false,
  isError: false,
  errorMessages: [],
};

type LogoutFailedAction = PayloadAction<{
  messages: string[];
}>;

export const logout = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    logout: () => ({
      loading: true,
      isError: false,
      errorMessages: [],
    }),
    logoutSuccess: () => ({
      loading: false,
      isError: false,
      errorMessages: [],
    }),
    logoutFailed: (_, action: LogoutFailedAction) => ({
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
