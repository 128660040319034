import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type MemberInformationState = {
  registering: boolean;
  isError: boolean;
  errorMessages: string[];
};

const initialState: MemberInformationState = {
  registering: false,
  isError: false,
  errorMessages: [],
};

type MemberInformationRegisterFailedAction = PayloadAction<{
  messages: string[];
}>;

export const memberInformation = createSlice({
  name: 'memberInformation',
  initialState,
  reducers: {
    register: () => ({
      registering: true,
      isError: false,
      errorMessages: [],
    }),
    registerSuccess: () => ({
      registering: true,
      isError: false,
      errorMessages: [],
    }),
    registerFailed: (_, action: MemberInformationRegisterFailedAction) => ({
      registering: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
