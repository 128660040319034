import * as React from 'react';
import merge from 'lodash.merge';

import { StyledEngineProvider } from '@mui/styled-engine-sc';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider as StafesThemeProvider } from '@stafes/gochikuru-now-ui/lib/styles/ThemeProvider';

import { GlobalStyle } from './GlobalStyle';
import { theme } from './theme';

const AppStylesProvider: React.FC = ({ children }) => {
  const muiTheme = createTheme();

  return (
    <StyledEngineProvider injectFirst>
      <StafesThemeProvider theme={merge(muiTheme, theme)}>
        <>
          <GlobalStyle />
          {children}
        </>
      </StafesThemeProvider>
    </StyledEngineProvider>
  );
};

export { AppStylesProvider };
