import { Middleware, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from './logger';
import checkPageChange from './checkPageChange';
import requestFailed from './requestFailed';

export const middleware: Middleware[] = [
  ...getDefaultMiddleware(),
  logger,
  checkPageChange,
  requestFailed,
];
