import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface CartState {
  submitting: boolean;
  isError: boolean;
  isOrdered: boolean;
  errorMessages: string[];
  payload: {
    cart?: Cart;
    cancellableTime?: string;
    couponId?: number;
    order?: {
      date: string;
      item?: Order;
    };
    deliveryCompanyBaseId?: number;
    deliveryCompanyBaseName: string;
  };
}

const initialState: CartState = {
  submitting: false,
  isError: false,
  isOrdered: false,
  errorMessages: [],
  payload: {
    cart: undefined,
    cancellableTime: undefined,
    couponId: undefined,
    order: undefined,
    deliveryCompanyBaseId: undefined,
    deliveryCompanyBaseName: '',
  },
};

type ConfirmLunchAction = PayloadAction<{
  items: CartItem[];
  date: string;
  cancellableTime: string;
  couponId?: number;
  totalQty: number;
  totalPrice: number;
  cartSubtotalPrice: number;
  cartWelfarePrice?: number;
  cartTotalPrice: number;
  priceDisplayStatus: PriceDisplayStatusType;
  isPriceVisible: boolean;
  paymentType: CompanyPaymentConfig;
  deliveryCompanyBaseId: number;
  deliveryCompanyBaseName: string;
}>;

type ConfirmLunchFailedAction = PayloadAction<{
  messages: string[];
}>;

type BuyLunchAction = PayloadAction<{
  order?: Order;
  date: string;
  cancellableTime: string;
  deliveryCompanyBaseName: string;
}>;

type BuyLunchFailedAction = PayloadAction<{
  messages: string[];
}>;

export const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    get: () => ({
      submitting: true,
      isError: false,
      isOrdered: false,
      errorMessages: [],
      payload: {
        cart: undefined,
        order: undefined,
        cancellableTime: undefined,
        couponId: undefined,
        isPriceVisible: false,
        deliveryCompanyBaseId: undefined,
        deliveryCompanyBaseName: '',
      },
    }),
    confirmLunchSucceed: (_, action: ConfirmLunchAction) => ({
      submitting: false,
      isError: false,
      isOrdered: false,
      errorMessages: [],
      payload: {
        cart: {
          date: action.payload.date,
          items: action.payload.items,
          totalQty: action.payload.totalQty,
          totalPrice: action.payload.totalPrice,
          cartSubtotalPrice: action.payload.cartSubtotalPrice,
          cartWelfarePrice: action.payload.cartWelfarePrice,
          cartTotalPrice: action.payload.cartTotalPrice,
          priceDisplayStatus: action.payload.priceDisplayStatus,
          isPriceVisible: action.payload.isPriceVisible,
          paymentType: action.payload.paymentType,
        },
        couponId: action.payload.couponId,
        cancellableTime: action.payload.cancellableTime,
        deliveryCompanyBaseId: action.payload.deliveryCompanyBaseId,
        deliveryCompanyBaseName: action.payload.deliveryCompanyBaseName,
      },
    }),
    confirmLunchFailed: (state, action: ConfirmLunchFailedAction) => ({
      ...state,
      submitting: false,
      isError: true,
      isOrdered: false,
      errorMessages: action.payload.messages,
    }),
    buyLunchSucceed: (_, action: BuyLunchAction) => ({
      submitting: false,
      isError: false,
      isOrdered: true,
      errorMessages: [],
      payload: {
        order: {
          date: action.payload.date,
          item: action.payload.order,
        },
        cancellableTime: action.payload.cancellableTime,
        deliveryCompanyBaseName: action.payload.deliveryCompanyBaseName,
      },
    }),
    buyLunchFailed: (state, action: BuyLunchFailedAction) => ({
      ...state,
      submitting: false,
      isError: true,
      isOrdered: false,
      errorMessages: action.payload.messages,
    }),
    flushCart: (state) => ({
      ...state,
      payload: {
        cart: undefined,
        couponId: undefined,
        order: undefined,
        deliveryCompanyBaseId: undefined,
        deliveryCompanyBaseName: '',
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
