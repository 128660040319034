import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface SelfInformation {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
  notificationSubmitting: boolean;
  notificationIsError: boolean;
  notificationErrorMessages: string[];
  payload?: {
    userName?: string;
    employeeNumber?: string;
    emailAddress?: string;
    emailVerifyRequired: boolean;
    companyBaseName: string;
    notification: TypeOfNotification;
    emailNotificationAvailable?: boolean;
    hasSlack?: boolean;
    slackChannelName?: string;
    slackTeamName?: string;
  };
}

const initialState: SelfInformation = {
  loading: true,
  isError: false,
  errorMessages: [],
  notificationSubmitting: true,
  notificationIsError: false,
  notificationErrorMessages: [],
};

type SelfInformationGetSuccessAction = PayloadAction<{
  userName?: string;
  companyBaseName: string;
  employeeNumber?: string;
  emailAddress?: string;
  emailVerifyRequired: boolean;
  approveTosAgreementRequired: boolean;
  registerUserInformationRequired: boolean;
  notification: TypeOfNotification;
  emailNotificationAvailable: boolean;
  hasSlack: boolean;
  slackChannelName?: string;
  slackTeamName?: string;
}>;

type SelfInformationGetFailedAction = PayloadAction<{
  messages: string[];
}>;

type ChangeNotificationSuccessAction = PayloadAction<{
  categoryId: number;
  isSubscribe: boolean;
}>;

type ChangeNotificationFailedAction = PayloadAction<{
  messages: string[];
}>;

export const selfInformation = createSlice({
  name: 'selfInformation',
  initialState,
  reducers: {
    get: () => ({
      loading: true,
      isError: false,
      errorMessages: [],
      notificationSubmitting: false,
      notificationIsError: false,
      notificationErrorMessages: [],
    }),
    getSuccess: (_, action: SelfInformationGetSuccessAction) => ({
      loading: false,
      isError: false,
      errorMessages: [],
      notificationSubmitting: false,
      notificationIsError: false,
      notificationErrorMessages: [],
      payload: {
        userName: action.payload.userName,
        companyBaseName: action.payload.companyBaseName,
        employeeNumber: action.payload.employeeNumber,
        emailAddress: action.payload.emailAddress,
        emailVerifyRequired: action.payload.emailVerifyRequired,
        notification: action.payload.notification,
        emailNotificationAvailable: action.payload.emailNotificationAvailable,
        hasSlack: action.payload.hasSlack,
        slackChannelName: action.payload.slackChannelName,
        slackTeamName: action.payload.slackTeamName,
      },
    }),
    getFailed: (_, action: SelfInformationGetFailedAction) => ({
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
      notificationSubmitting: false,
      notificationIsError: false,
      notificationErrorMessages: [],
    }),
    changeNotification: (state) => ({
      ...state,
      notificationSubmitting: true,
      notificationIsError: false,
      notificationErrorMessages: [],
    }),
    changeNotificationSuccess: (
      state,
      action: ChangeNotificationSuccessAction,
    ) => {
      const { categoryId, isSubscribe } = action.payload;

      const { ...currentPayload } = state.payload;
      const currentNotification = currentPayload.notification;

      const notification = Object.keys(
        currentNotification,
      ).reduce<TypeOfNotification>(
        (prev, cur) => ({
          ...prev,
          [cur]: currentNotification[cur as NotificationType]?.map((item) => {
            if (item.categoryId === categoryId) {
              return {
                ...item,
                isSubscribe,
              };
            }
            return item;
          }),
        }),
        {},
      );

      return {
        ...state,
        notificationSubmitting: false,
        notificationIsError: false,
        notificationErrorMessages: [],
        payload: {
          ...currentPayload,
          notification,
        },
      };
    },
    changeNotificationFailed: (
      state,
      action: ChangeNotificationFailedAction,
    ) => ({
      ...state,
      notificationSubmitting: false,
      notificationIsError: true,
      notificationErrorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
