import { Middleware } from '../types';

const logger: Middleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    console.group(action.type);
    console.info('dispatching', action);
    const result = next(action);
    console.log('next state', getState());
    console.groupEnd();
    return result;
  };

export default logger;
