// ルーティングを定数管理
// TODO 絶対もっといい管理方法ある
export const routes = {
  login: '/login',
  top: '/top',
  lunchConfirmation: '/lunch/confirmation',
  lunchComplete: '/lunch/complete',
  settings: '/settings',
  deleteIndex: '/settings/delete',
  deleteAccepted: '/settings/delete/accepted',
  menus: '/menus',
  orders: '/orders',
  ordersCancelConfirmation: '/orders/cancel/confirmation',
  ordersCancelComplete: '/orders/cancel/complete',
  ordersReceiveConfirmation: '/orders/receive/confirmation',
  ordersReceiveComplete: '/orders/receive/complete',
  paymentMethod: '/payment_method',
  registerPaymentMethod: '/register_payment_method',
  savePaymentMethodDone: '/save_payment_method_done',
  tosAgreement: '/tos_agreement',
  deletePaymentMethod: '/delete_payment_method',
  modifyEmailRequest: '/modify_email_request',
  modifyEmailRequestDone: '/modify_email_request_done',
  modifyMemberInformation: '/modify_member_information',
  modifyMemberInformationDone: '/modify_member_information_done',
  registerCompanyBase: '/register/company_base',
  registerMember: '/register/member',
  registerComplete: '/register/complete',
  registerTosAgreement: '/register_tos_agreement',
  registerMemberInformation: '/register_member_information',
  resetPasswordRequest: '/reset_password_request',
  updatePassword: '/update_password',
  help: '/help',
  contact: '/contact',
  contactOrders: '/contact/orders',
  contactOthers: '/contact/others',
  contactComplete: '/contact/complete',
  coupons: '/coupons',
};
