import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type LoginState = {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
};

const initialState: LoginState = {
  loading: false,
  isError: false,
  errorMessages: [],
};

type LoginFailedAction = PayloadAction<{
  messages: string[];
}>;

export const login = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state) => ({
      ...state,
      loading: true,
    }),
    loginSuccess: () => ({
      loading: false,
      isError: false,
      errorMessages: [],
    }),
    loginFailed: (_, action: LoginFailedAction) => ({
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, (state, action) => {
      // Slack認証callbackからの戻りの場合stateを保持する see also
      // https://trello.com/c/TxQJH7r0
      // https://github.com/stafes/gochikuru-now-front/pull/229
      if (action.payload.oldUrl === '/slack_oauth_callback') {
        return state;
      }
      return initialState;
    });
  },
});
