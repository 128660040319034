import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface SelfOrders {
  loading: boolean;
  errorMessages: string[];
  payload: {
    orders: OrderWithPrice[];
    hasNext: boolean;
  };
}

const initialState: SelfOrders = {
  loading: true,
  errorMessages: [],
  payload: {
    orders: [],
    hasNext: false,
  },
};

type SelfOrdersGetSuccessAction = PayloadAction<{
  orders: OrderWithPrice[];
  hasNext: boolean;
}>;

type SelfOrdersGetFailedAction = PayloadAction<{
  messages: string[];
}>;

export const selfOrders = createSlice({
  name: 'selfOrders',
  initialState,
  reducers: {
    get: (state) => ({
      ...state,
      loading: true,
    }),
    getSuccess: (state, action: SelfOrdersGetSuccessAction) => ({
      loading: false,
      errorMessages: [],
      payload: {
        orders: [...state.payload.orders, ...action.payload.orders],
        hasNext: action.payload.hasNext,
      },
    }),
    getFailed: (state, action: SelfOrdersGetFailedAction) => ({
      ...state,
      loading: false,
      errorMessages: action.payload.messages,
    }),
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, (state, action) => {
      // お問い合わせで注文番号を入力して注文詳細を表示する場合stateを保持する
      if (action.payload.oldUrl === '/contact/orders') {
        return state;
      }
      return initialState;
    });
  },
});
