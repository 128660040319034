import { combineReducers } from '@reduxjs/toolkit';

import { cancelOrder } from './modules/cancelOrder';
import { cart } from './modules/cart';
import { connectWithSlack } from './modules/connectWithSlack';
import { coupon } from './modules/coupon';
import { login } from './modules/login';
import { logout } from './modules/logout';
import { memberInformation } from './modules/memberInformation';
import { modifyEmailRequest } from './modules/modifyEmailRequest';
import { modifyEmail } from './modules/modifyEmail';
import { modifyMemberInformation } from './modules/modifyMemberInformation';
import { news } from './modules/news';
import { paymentMethods } from './modules/paymentMethods';
import { prepareForDelete } from './modules/prepareForDelete';
import { receiveOrder } from './modules/receiveOrder';
import { resetPasswordRequest } from './modules/resetPasswordRequest';
import { router } from './modules/router';
import { selfCompanyBases } from './modules/selfCompanyBases';
import { selfInformation } from './modules/selfInformation';
import { selfOrders } from './modules/selfOrders';
import { session } from './modules/session';
import { setPassword } from './modules/setPassword';
import { stripePaymentMethod } from './modules/stripePaymentMethod';
import { lunch } from './modules/lunch';
import { tosAgreement } from './modules/tosAgreement';
import { updatePassword } from './modules/updatePassword';
import { verifyCompanyBase } from './modules/verifyCompanyBase';
import { verifyEmail } from './modules/verifyEmail';
import { verifyToken } from './modules/verifyToken';
import { registerNewMember } from './modules/registerNewMember';
import { resendRegisterMail } from './modules/resendRegisterMail';
import { requestSupport } from './modules/requestSupport';

export const reducer = combineReducers({
  cancelOrder: cancelOrder.reducer,
  cart: cart.reducer,
  connectWithSlack: connectWithSlack.reducer,
  coupon: coupon.reducer,
  login: login.reducer,
  logout: logout.reducer,
  memberInformation: memberInformation.reducer,
  modifyEmailRequest: modifyEmailRequest.reducer,
  modifyEmail: modifyEmail.reducer,
  modifyMemberInformation: modifyMemberInformation.reducer,
  news: news.reducer,
  paymentMethods: paymentMethods.reducer,
  prepareForDelete: prepareForDelete.reducer,
  receiveOrder: receiveOrder.reducer,
  resetPasswordRequest: resetPasswordRequest.reducer,
  router: router.reducer,
  selfCompanyBases: selfCompanyBases.reducer,
  selfInformation: selfInformation.reducer,
  selfOrders: selfOrders.reducer,
  session: session.reducer,
  setPassword: setPassword.reducer,
  stripePaymentMethod: stripePaymentMethod.reducer,
  lunch: lunch.reducer,
  tosAgreement: tosAgreement.reducer,
  updatePassword: updatePassword.reducer,
  verifyCompanyBase: verifyCompanyBase.reducer,
  verifyEmail: verifyEmail.reducer,
  verifyToken: verifyToken.reducer,
  registerNewMember: registerNewMember.reducer,
  resendRegisterMail: resendRegisterMail.reducer,
  requestSupport: requestSupport.reducer,
});

export type Reducer = typeof reducer;
