import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

type StripePaymentMethodState = {
  save: {
    saving: boolean;
    isError: boolean;
    errorMessages: string[];
  };
  delete: {
    deleting: boolean;
    isError: boolean;
    errorMessages: string[];
  };
};

const initialState: StripePaymentMethodState = {
  save: {
    saving: false,
    isError: false,
    errorMessages: [],
  },
  delete: {
    deleting: false,
    isError: false,
    errorMessages: [],
  },
};

type StripePaymentMethodSaveFailed = PayloadAction<{
  messages: string[];
}>;

type StripePaymentMethodDeleteFailed = PayloadAction<{
  messages: string[];
}>;

export const stripePaymentMethod = createSlice({
  name: 'stripePaymentMethod',
  initialState,
  reducers: {
    save: (state) => {
      state.save = {
        saving: true,
        isError: false,
        errorMessages: [],
      };
    },
    saveSuccess: (state) => {
      state.save = {
        saving: false,
        isError: false,
        errorMessages: [],
      };
    },
    saveFailed: (state, action: StripePaymentMethodSaveFailed) => {
      state.save = {
        saving: false,
        isError: true,
        errorMessages: action.payload.messages,
      };
    },
    delete: (state) => {
      state.delete = {
        deleting: true,
        isError: false,
        errorMessages: [],
      };
    },
    deleteSuccess: (state) => {
      state.delete = {
        deleting: false,
        isError: false,
        errorMessages: [],
      };
    },
    deleteFailed: (state, action: StripePaymentMethodDeleteFailed) => {
      state.delete = {
        deleting: false,
        isError: true,
        errorMessages: action.payload.messages,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
