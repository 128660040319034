import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface SelfCompanyBases {
  loading: boolean;
  companyBases: CompanyBase[];
  errorMessages: string[];
}

const initialState: SelfCompanyBases = {
  loading: true,
  companyBases: [],
  errorMessages: [],
};

type SelfCompanyBasesGetSuccessAction = PayloadAction<{
  companyBases: CompanyBase[];
}>;

type SelfCompanyBasesGetFailedAction = PayloadAction<{
  messages: string[];
}>;

export const selfCompanyBases = createSlice({
  name: 'selfCompanyBases',
  initialState,
  reducers: {
    get: (state) => ({
      ...state,
      loading: true,
    }),
    getSuccess: (state, action: SelfCompanyBasesGetSuccessAction) => ({
      ...state,
      loading: false,
      errorMessages: [],
      companyBases: action.payload.companyBases,
    }),
    getFailed: (state, action: SelfCompanyBasesGetFailedAction) => ({
      ...state,
      loading: false,
      errorMessages: action.payload.messages,
    }),
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
