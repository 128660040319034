import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { router } from './router';

interface ReceiveOrderState {
  loading: boolean;
  isError: boolean;
  errorMessages: string[];
  payload: {
    order?: ReceiveOrder;
  };
}

const initialState: ReceiveOrderState = {
  loading: false,
  isError: false,
  errorMessages: [],
  payload: {
    order: undefined,
  },
};

type ConfirmReceiveOrderSuccessAction = PayloadAction<{
  order: ReceiveOrder;
}>;

type ConfirmReceiveOrderFailedAction = PayloadAction<{
  messages: string[];
}>;

type ReceiveOrderFailedAction = PayloadAction<{
  messages: string[];
}>;

export const receiveOrder = createSlice({
  name: 'receiveOrder',
  initialState,
  reducers: {
    receiveOrder: () => ({
      loading: true,
      isError: false,
      errorMessages: [],
      payload: {
        order: undefined,
      },
    }),
    confirmReceiveOrderSucceed: (
      _,
      action: ConfirmReceiveOrderSuccessAction,
    ) => ({
      loading: false,
      isError: false,
      errorMessages: [],
      payload: {
        order: action.payload.order,
      },
    }),
    confirmReceiveOrderFailed: (
      state,
      action: ConfirmReceiveOrderFailedAction,
    ) => ({
      ...state,
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
    receiveOrderSucceed: (state) => ({
      loading: false,
      isError: false,
      errorMessages: [],
      payload: {
        order: state.payload.order,
      },
    }),
    receiveOrderFailed: (state, action: ReceiveOrderFailedAction) => ({
      ...state,
      loading: false,
      isError: true,
      errorMessages: action.payload.messages,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(router.actions.pageChange, () => initialState);
  },
});
